$(function () {
    'use strict';

    let resizedFinished = [];

    const isTouchDevice = 'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;

    const buttonsDefault = [
        { extend: 'csv', text: 'Download CSV', className: 'mr-4' },
        { extend: 'copy', text: 'Copy to Clipboard' }
    ];

    let formats = { 'number': [], 'USD': [] };

    if ("Intl" in window && Intl.NumberFormat) {
        formats['number']['human'] = Intl.NumberFormat("en", {
            notation: "compact",
            maximumSignificantDigits: 3
        });
        formats['USD']['human'] = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: 'USD',
            notation: "compact",
            maximumSignificantDigits: 3
        });
    }
    else {
        formats['number']['human'] = {
            format: function (e) {
                return e
            }
        };
        formats['USD']['human'] = {
            format: function (e) {
                return e
            }
        };
    };

    function railsEnv() {
        return $('#mini-profiler').length ? 'development' : 'production';
    }

    function debug(msg) {
        if (railsEnv() == 'development') {
            console.log(msg);
        }
    }

    function datatableHeaderOffset() {
        return $('nav').parent().css('position') === 'fixed' ? $('nav').outerHeight() : 0;
    }

    $.extend(true, $.fn.dataTable.defaults, {
        autoWidth: false,
        bLengthChange: false,
        createdRow: (row, data, dataIndex, cells) => {
            let html_columns = cells.length;
            let json_columns = data.length;
            if (html_columns != json_columns) {
                debug('Wrong columns count for HTML table: Datatables columns = ' + html_columns + ', Json columns = ' + json_columns);
            }

            if (!row.className) {
                $(row).addClass('bg-gray-50 even:bg-white hover:bg-gray-200');
            }
        },
        fixedHeader: ({ headerOffset: datatableHeaderOffset() }),
        language: { search: '' },
        order: [],
        pageLength: 50,
        scrollX: true,
        initComplete: function (settings, json) {
            $('.dataTables_filter input[type=search]').each(function () {
                $(this).attr("id", $('.dataTables_filter').attr('id') + '_input');
                $(this).attr("placeholder", "Filter...");
            });

            let dataTableOb = settings.oInstance.api();
            let tableId = dataTableOb.table().node().id;
            if(tableId) {
                // fix th width in #tableId_wrapper {}
                let cssRule = '';
                $('#' + tableId + ' th').each(function (i, obj) {
                    cssRule += 'th:nth-child(' + (i + 1) + '){ width: ' + $(obj).css('width') + '}';
                });
                document.styleSheets[0].insertRule('#' + tableId + '_wrapper {' + cssRule + '}');

                // fix table header position after window resize
                resizedFinished[tableId] = null;
                $(window).on('resize', function() {
                    clearTimeout(resizedFinished[tableId]);
                    resizedFinished[tableId] = setTimeout(function(){
                        dataTableOb.fixedHeader.headerOffset(datatableHeaderOffset());
                    }, 50);
                });
            }
        },
        drawCallback: function (settings) {
            settings.oInstance.api().fixedHeader.adjust();
        }
    });

    // Homepage - /
    $(function () {
        if (!document.querySelector('#homeStockStats')) return;
        homeStats();
        // $.when(homeStats()).done(function() { });
    });

    function addClassToTdNotTh(className) {
        return (td, cellData, rowData, rowIndex, colIndex) => {
            $(td).addClass(className);
        }
    };

    function isString(value) {
        return typeof value === 'string' || value instanceof String;
    }

    function validateArray(data, col, columnType, arrayLength) {
        if (!Array.isArray(data)) {
            debug('Error: Column: ' + (col === null ? '(unknown)' : col + 1) + ': Wrong variable type for ' + columnType + ': ' + (typeof data) + ', value: ' + data + ', expected Array[' + arrayLength + ']');
            return false;
        }
        else if (data.length != arrayLength) {
            debug('Error: Column: ' + (col === null ? '(unknown)' : col + 1) + ': Wrong argument length: ' + data.length + ', value: ' + data + ', expected: Array[' + arrayLength + ']');
            return false;
        }
        return true;
    }

    function validateString(data, col, columnType) {
        if (isString(data) || data === null) {
            return true;
        }
        else {
            debug('Error: Column: ' + (col === null ? '(unknown)' : col + 1) + ': Wrong variable type for ' + columnType + ': ' + (typeof data) + ', value: ' + data + ', expected: string');
            return false;
        }
    }

    function validateNumber(data, col, columnType) {
        if (isNaN(data)) {
            debug('Error: Column: ' + (col === null ? '(unknown)' : col + 1) + ': Wrong variable type for ' + columnType + ': ' + (typeof data) + ', value: ' + data + ', expected: number');
            return false;
        }
        return true;
    }

    function mdy(dateString) {
        if (dateString && validateString(dateString, null, 'mdy()')) {
            let parts = dateString.split('-');
            if (parts.length === 3) {
                return [+parts[1], +parts[2], +parts[0]].join('/');
            }
            else {
                debug('Function mdy() data Error: ' + dateString);
            }
        }
        return '';
    }

    // https://stackoverflow.com/questions/7342957/how-do-you-round-to-one-decimal-place-in-javascript
    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    function errorHandler(xhr, status, error) {
        // alert only on production ENV
        if (railsEnv() == 'production') {
            alert('Sorry, something went wrong. You could try reloading the page');
        }
        else {
            console.log('URL: ' + this.url);
            console.log('Status: ' + xhr.status);
            console.log('Response: ' + xhr.responseText);
            // console.log(error);
            // console.log(this);
            // console.log(xhr);
        }
    }

    let centeredTextOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-center');
            validateString(cellData, col, 'centeredTextOptions');
        },
    };

    let numberOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateNumber(cellData, col, 'numberOptions');
        },
        orderSequence: ['desc', 'asc'],
        render: function (value, type, row) {
            if (type === 'display') {
                value = $.fn.dataTable.render.number(',', '.', 0).display(value);
            }
            else if (type === 'sort') {
                return value == null ? 0 : value;
            }

            return value;
        }
    };

    let humanOptions = [];
    ['number', 'USD'].forEach(async (unit) => {
        humanOptions[unit] = {
            createdCell: function (td, cellData, rowData, row, col) {
                $(td).addClass('text-right');
                validateNumber(cellData, col, 'humanOptions[' + unit + ']');
            },
            orderSequence: ['desc', 'asc'],
            render: function (value, type, row) {
                if (type === 'display' && value !== null) {
                    value = formats[unit]['human'].format(round(value)); //.replace(/([0-9])([a-zA-Z])/, "$1 $2");
                }
                else if (type === 'sort') {
                    return value == null ? 0 : value;
                }
                return value;
            }
        }
    });

    let numberOptionsSign = {
        // createdCell: addClassToTdNotTh('text-right'),
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateNumber(cellData, col, 'numberOptionsSign');

            if (cellData < 0) {
                $(td).css('color', 'red')
            }
            else if (cellData > 0) {
                $(td).css('color', 'green')
            }
        },

        render: function (value, type, row) {
            if (type === 'display') {
                let val = $.fn.dataTable.render.number(',', '.', 0).display(value);

                if (value > 0) {
                    val = '+' + val;
                }
                else if (value == 0) {
                    val = ''
                }

                value = val
            }
            else if (type === 'sort') {
                return value == null ? 0 : value;
            }

            return value;
        }
    };

    let humanOptionsSign = [];
    ['number', 'USD'].forEach(async (unit) => {
        humanOptionsSign[unit] = {
            createdCell: function (td, cellData, rowData, row, col) {
                $(td).addClass('text-right');
                validateNumber(cellData, col, 'humanOptionsSign[' + unit + ']');
                if (cellData < 0) {
                    $(td).css('color', 'red')
                }
                else if (cellData > 0) {
                    $(td).css('color', 'green')
                }
            },
            render: function (value, type, row) {
                if (type === 'display') {
                    let val = value === null ? value : formats[unit]['human'].format(round(value)); //.replace(/([0-9])([a-zA-Z])/, "$1 $2");
                    if (value > 0) {
                        val = '+' + val;
                    }
                    else if (value == 0) {
                        val = ''
                    }
                    value = val
                }
                else if (type === 'sort') {
                    return value == null ? 0 : value;
                }
                return value;
            }
        }
    });

    let decimalOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateNumber(cellData, col, 'decimalOptions');
        },
        render: function (value, type, row) {
            if (type === 'display') {
                value = $.fn.dataTable.render.number(',', '.', 2).display(value);
            }
            else if (type === 'sort') {
                return value == null ? Infinity : value;
            }

            return value;
        }

    };

    let priceProperOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');

            if (validateArray(cellData, col, 'priceProperOptions', 2) && !cellData[1]) {
                $(td).css('color', 'gray')
            }
        },
        render: function (value, type, row) {
            if (Array.isArray(value) && value.length == 2) {
                let price = value[0];
                let proper_price = value[1];

                if (type === 'display') {
                    value = $.fn.dataTable.render.number(',', '.', 2).display(price);
                    if (!proper_price && value !== null) {
                        value = value + '<sup>*</sup>';
                    }
                    return value;
                }
                else if (type === 'sort') {
                    if (proper_price == false) {
                        price = 0;
                    }
                    return price;
                }

                return price;
            }
        }
    };

    let numberOrCurrencyOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateArray(cellData, col, 'numberOrCurrencyOptions', 2);
        },
        render: function (data, type, row) {
            if (Array.isArray(data) && data.length == 2) {
                let value = data[0];
                let format = data[1];
                let result = value;

                if (type === 'display') {
                    switch (format) {
                        case 'currency':
                            result = formats['USD']['human'].format(round(value));
                            break;
                        case 'number':
                            result = formats['number']['human'].format(round(value));
                            break;
                    }
                }
                else if (type === 'sort') {
                    return value;
                }
                return result;
            }
        }
    };

    // The parseFloat() function returns a floating-point number parsed from the given string or NaN if the first non-whitespace character cannot be converted to a number.
    // The Number.toFixed() method formats a number using fixed-point notation and can only be called on numbers.
    let pctOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateNumber(cellData, col, 'pctOptions');
        },
        orderSequence: ['desc', 'asc'],
        render: function (pct, type, row) {
            if (type === 'display') {
                if (pct == null) {
                    return '';
                }
                else if (Math.abs(pct) > 0) {
                    let digits = Math.abs(pct) >= 10 ? 1 : 2;
                    return round(pct, digits) + '%';
                }
                // 0% case
                else {
                    return ''
                }
            }
            else if (type === 'sort') {
                if (pct == null) {
                    return '';
                }
            }

            return pct;
        }
    };

    let pctOptionsSign = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateNumber(cellData, col, 'pctOptionsSign');
        },
        orderSequence: ['desc', 'asc'],
        render: function (pct, type, row) {
            if (type === 'display') {
                let pct_string = '';
                if (pct == null) {
                    return '';
                }
                else if (Math.abs(pct) > 0) {
                    let digits = Math.abs(pct) >= 10 ? 1 : 2;
                    pct_string = round(pct, digits) + '%';
                    if (pct > 0) {
                        pct_string = '+' + pct_string;
                    }
                    return pct_string;
                }
                // 0% case
                else {
                    return ''
                }

            }
            else if (type === 'sort') {
                if (pct == null) {
                    return '';
                }
            }

            return pct;
        }
    };

    // only for portfolio_version data
    let comparisonPctOptions = {
        orderSequence: ['desc', 'asc'],
        createdCell: function (td, cellData, rowData, row, col) {
            let bgClass;
            $(td).addClass('text-right truncate hover:overflow-visible');
            validateNumber(cellData, col, 'comparisonPctOptions');

            if (cellData < 0) {
                $(td).css('color', 'red')
            }
            else if (cellData > 0) {
                $(td).css('color', 'green')
            }

            if (cellData === null) {
                bgClass = 'bg-green-100 hover:bg-green-200';
            } else if (cellData == -100) {
                bgClass = 'bg-red-100 bg-red-100 hover:bg-red-200';
            }
            $(td).parent().addClass(bgClass);
        },
        render: function (pct, type, row, meta) {
            if (type === 'display') {
                // return pct == null ? 'NEW' : Math.round(pct) + '%';
                let pct_string = '';

                if (pct == null) {
                    return 'NEW';
                }
                // else if (parseFloat(pct) == -100.0) {
                //   return 'SOLD OUT';
                // }
                else if (Math.abs(pct) > 0) {
                    let digits = Math.abs(pct) >= 10 ? 1 : 2;
                    pct_string = round(pct, digits) + '%';
                    if (pct > 0) {
                        pct_string = '+' + pct_string;
                    }
                    return pct_string;
                }
                else {
                    return ''
                }
            }
            else if (type === 'sort') {
                return pct == null ? Infinity : pct;
            }

            return pct;
        }
    };

    let miniPctOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right truncate');
            validateNumber(cellData, col, 'miniPctOptions');

            if (cellData < 0) {
                $(td).css('color', 'red')
            }
            else if (cellData > 0) {
                $(td).css('color', 'green')
            }
        },
        render: function (pct, type, row, meta) {
            if (type === 'display') {
                let pct_string = '';

                if (Math.abs(pct) > 0) {
                    pct_string = round(pct, 3) + '%';
                    if (pct > 0) {
                        pct_string = '+' + pct_string;
                    }
                    return pct_string;
                }
                else {
                    return ''
                }
            }
            else if (type === 'sort') {
                return pct == null ? Infinity : pct;
            }

            return pct;
        }
    };

    let otherManagerOptions = {
        createdCell: addClassToTdNotTh('text-center truncate')
    };

    let dateFiledOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateString(cellData, col, 'dateFiledOptions');
        },
        render: function (data, type, row) {
            if (type === 'display') {
                return data == null ? '' : mdy(data);
            }

            return data;
        }
    };

    let dateLink = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('text-right');
            validateArray(cellData, col, 'dateLink', 2);
        },
        render: function (data, type, row) {
            if (Array.isArray(data)) {
                if (type === 'display') {
                    return `<a href="${data[1]}">${mdy(data[0])}</a>`;
                }
                return data[0];
            }

            return data;
        }
    };

    let overflowLink = {
        createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('truncate group hover:overflow-visible');
            validateArray(cellData, col, 'overflowLink', 2);
        },
        render: function (data, type, row) {
            if (Array.isArray(data)) {
                if (isString(data[0]) && type === 'display' && data[0].trim().length) {
                    return `<span class="overflow-label"><a href="${data[1]}">${data[0]}</a></span>`;
                }
                return data[0];
            }

            return data;
        }
    };

    let overflowOptions = {
        createdCell: function (td, cellData, rowData, row, col) {
            let bgClass;
            $(td).addClass('truncate group hover:overflow-visible');

            if (validateString(cellData, col, 'overflowOptions')) {
                if (cellData.includes('Purchase')) {
                    bgClass = 'bg-green-100 hover:bg-green-200';
                } else if (cellData.includes('Sale')) {
                    bgClass = 'bg-red-100 bg-red-100 hover:bg-red-200';
                }
                $(td).parent().addClass(bgClass);
            }
        },
        render: function (data, type, row) {
            if (isString(data) && type === 'display' && data.trim().length) {
                return `<span class="overflow-label">${data}</span>`;
            }

            return data;
        }
    };

    // homepage - feature components
    $('#featuredInvestors, #featuredInsiders, #featuredStocks').DataTable({
        searching: false,
        dom: 'l<"w-52">rt'
    });

    // https://stackoverflow.com/questions/65495442/how-to-use-one-ajax-datasource-with-multiple-jquery-datatables
    function homeStats() {
        if (!document.querySelector('#homeStockStats')) return;

        return $.ajax({
            cache: true,
            error: errorHandler,
            url: $('#homeStockStats').data('url'),
            success: function (data) {

                $('#homeStockStats').DataTable({
                    data: data.stocks,
                    buttons: buttonsDefault,
                    columns: [
                        overflowLink,
                        overflowLink,
                        humanOptionsSign['USD'],
                        humanOptionsSign['USD'],
                        humanOptionsSign['USD'],
                        numberOptionsSign,
                        numberOptionsSign,
                        numberOptionsSign,
                        decimalOptions,
                        decimalOptions,
                        dateFiledOptions,
                        dateFiledOptions
                    ],
                    columnDefs: [
                        { targets: [2, 4, 5, 7, 10, 11], orderSequence: ['desc', 'asc'] }
                    ],
                    searching: false,
                    // https://datatables.net/reference/option/dom
                    dom: 'l<"w-52"f>rti'
                });

                $('#homeSecurityStats').DataTable({
                    data: data.securities,
                    buttons: buttonsDefault,
                    columns: [
                        overflowLink,
                        overflowLink,
                        overflowOptions,
                        miniPctOptions,
                        miniPctOptions,
                        miniPctOptions,
                        numberOptionsSign,
                        numberOptionsSign,
                        numberOptionsSign,
                        decimalOptions,
                        humanOptionsSign['USD']
                    ],
                    columnDefs: [
                        { targets: [3, 5, 6, 8, 10], orderSequence: ['desc', 'asc'] }
                    ],
                    searching: false,
                    dom: 'l<"w-52"f>rti'
                });

            },
        });
    }

    // Portfolio show - /portfolio/000156761920015270-parallax-volatility-advisers-l-p-q2-2020
    $('#filingAggregated').DataTable({
        ajax: {
            cache: true,
            dataSrc: function (json) {
                let portfolioChartData = {
                    labels: [],
                    urls: [],
                    data: [],
                    dataset_label: 'Holdings share',
                    unit: '%',
                    type: 'doughnut'
                };
                let data_visible_limit = 15;
                let rest_value = 0.0;
                let rest_count = 0;
                json.data.forEach((row => {
                    if (typeof row[1] === 'number') {
                        if (data_visible_limit > 0) {
                            let company_label = row[3][0] + (row[0][0] ? ' (' + row[0][0] + ')' : '');
                            portfolioChartData.labels.push(company_label);
                            portfolioChartData.urls.push(row[0][1]);
                            portfolioChartData.data.push(row[1]);
                            data_visible_limit--;
                        }
                        else if (row[1]) {
                            rest_value += row[1];
                            rest_count++;
                        }
                    }
                }
                ));
                if (portfolioChartData.data.length > 0 && rest_value > 0.0) {
                    portfolioChartData.labels.push('Other ' + rest_count + ' holding(s)');
                    portfolioChartData.data.push(rest_value);
                }
                if (portfolioChartData.data.length > 0) {
                    $('#portfolio-chart').parent().parent().show();
                }
                mychart('portfolio-chart', [portfolioChartData]);

                return json.data;
            },
            error: errorHandler,
            url: $('#filingAggregated').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            pctOptions,
            comparisonPctOptions,
            overflowLink,
            overflowOptions,
            humanOptionsSign['number'],
            comparisonPctOptions,
            humanOptionsSign['USD'],
            humanOptions['USD'],
            priceProperOptions,
            humanOptions['number'],
            humanOptions['USD']
        ],
        dom: 'l<"w-52"f>rtpiB'
    });

    // Portfolio show - /portfolio/000156761920015270-parallax-volatility-advisers-l-p-q2-2020
    $('#optionsAggregated').DataTable({
        ajax: {
            cache: true,
            // dataSrc: function (json) {
            //     let portfolioChartData = {
            //         labels: [],
            //         data: [],
            //         dataset_label: 'Holdings share',
            //         unit: '%',
            //         type: 'doughnut'
            //     };
            //     let data_visible_limit = 15;
            //     let rest_value = 0.0;
            //     json.data.forEach((row => {
            //         if (typeof row[1] === 'number') {
            //             if (data_visible_limit > 0) {
            //                 let company_label = row[3][0] + (row[0][0] ? ' (' + row[0][0] + ')' : '');
            //                 portfolioChartData.labels.push(company_label);
            //                 portfolioChartData.data.push(row[1]);
            //                 data_visible_limit -= 1;
            //             }
            //             else {
            //                 rest_value += row[1];
            //             }
            //         }
            //     }
            //     ));
            //     if (portfolioChartData.data.length > 0 && rest_value > 0.0) {
            //         portfolioChartData.labels.push('Other holdings');
            //         portfolioChartData.data.push(rest_value);
            //     }
            //     if (portfolioChartData.data.length > 0) {
            //         $('#portfolio-chart').parent().parent().show();
            //     }
            //     mychart('portfolio-chart', [portfolioChartData]);

            //     return json.data;
            // },
            error: errorHandler,
            url: $('#optionsAggregated').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            overflowLink,
            overflowOptions,
            overflowOptions,
            humanOptionsSign['number'],
            comparisonPctOptions,
            priceProperOptions,
            humanOptions['number'],
            humanOptions['USD']
        ],
        dom: 'l<"w-52"f>rtpiB'
    });

    // Portfolio detailed
    // /portfolio/000156761920015270-parallax-volatility-advisers-l-p-q2-2020/detailed
    $('#filingDetailed').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#filingDetailed').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            pctOptions,
            overflowLink,
            overflowOptions,
            centeredTextOptions,
            humanOptions['USD'],
            humanOptions['number'],
            humanOptions['number'],
            centeredTextOptions,
            otherManagerOptions,
            humanOptions['number'],
            humanOptions['number'],
            humanOptions['number']
        ],
        columnDefs: [
            { targets: [10, 11, 12], width: '8rem', className: 'truncate' }
        ],
        dom: 'l<"w-52"f>rtpiB'
    });

    // Compare
    // /portfolio/000108636423000015/compare/000083423722010526
    $('#filingComparison').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#filingComparison').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowOptions,
            overflowLink,
            overflowOptions,
            centeredTextOptions,
            humanOptions['number'],
            humanOptions['number'],
            humanOptionsSign['number'],
            comparisonPctOptions,
            humanOptions['USD'],
            humanOptions['USD'],
            humanOptionsSign['USD'],
            comparisonPctOptions
        ],
        dom: 'l<"w-52"f>rtpiB'
    });

    // Investor & Security holdings
    // /investor/0001364742/investment/037833100
    $('#investorSecurityHoldings').DataTable({
        buttons: buttonsDefault,
        columnDefs: [
            { targets: [0, 1, 2, 3, 4, 5, 6], orderSequence: ['desc', 'asc'] },
            {
                targets: [7],
                orderSequence: ['desc', 'asc'],
                render: {
                    sort: function (a) { return a == '' ? Infinity : a; }
                }
            }
        ],
        dom: 'l<"w-52"f>rtpiB'
    });

    // Security Holdings by quarter
    // /investment/037833100/2023/1
    $('#allSecurityHolders').DataTable({
        ajax: {
            cache: true,
            dataSrc: function (json) {
                // let val = $.fn.dataTable.render.number(',', '.', 0, '$').display(json.total_value);
                // $('.total-value').html(val);
                return json.data;
            },
            error: errorHandler,
            url: $('#allSecurityHolders').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            centeredTextOptions,
            pctOptions,
            comparisonPctOptions,
            humanOptions['USD'],
            priceProperOptions,
            humanOptions['number'],
            humanOptionsSign['number'],
            comparisonPctOptions,
            dateLink
        ],
        columnDefs: [
            { targets: [2, 3, 4], orderSequence: ['desc', 'asc'] }
        ],
        dom: 'l<"w-52"f>rtpiB'
    });

    // Investor Show
    // /investor/0001364742-blackrock-inc
    $('#investorFilings').DataTable({
        columnDefs: [
            { targets: [1, 2, 3, 4, 5], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rtpi'
    });

    // CompanyTag
    // /stock-tag/meta/ratio/ROA/USD?freq=Y
    $('#companyTags, #companyTagsRatio, #companyTagsInstant, #companyTagsInstantRatio, #companyTagsYear').DataTable({
        columnDefs: [
            { targets: [0, 1, 2, 3, 4], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rtpi'
    });

    // Insider show
    // /insider/0001295231-page-lawrence
    $('#insiderClasses').DataTable({
        columnDefs: [
            { targets: [4], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rtpi'
    });

    $('#insiderTransactionsAggregated').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#insiderTransactionsAggregated').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            overflowLink,
            overflowOptions,
            overflowOptions,
            pctOptionsSign,
            humanOptions['USD'],
            priceProperOptions,
            humanOptionsSign['number'],
            numberOrCurrencyOptions,
            dateFiledOptions,
            overflowOptions
        ],
        searching: true,
        dom: 'l<"w-52"f>rtpiB'
    });

    // Stock show
    // /stock/goog
    // tables should be initialized in the same order as in html
    $('#stockBuysSells').DataTable({
        searching: false,
        dom: 'l<"w-52"f>rti'
    });

    $('#insiderStockBuysSells').DataTable({
        searching: false,
        dom: 'l<"w-52"f>rti'
    });

    $('#stockInsiders').DataTable({
        searching: false,
        columnDefs: [
            { targets: [3, 4], orderSequence: ['desc', 'asc'] }
        ],
        dom: 'l<"w-52"f>rtpi'
    });

    $('#stockTransactionsAggregated').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#stockTransactionsAggregated').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            overflowLink,
            overflowLink,
            overflowOptions,
            pctOptionsSign,
            humanOptions['USD'],
            priceProperOptions,
            humanOptionsSign['number'],
            numberOrCurrencyOptions,
            dateFiledOptions,
            overflowOptions
        ],
        searching: true,
        dom: 'l<"w-52"f>rtpiB'
    });

    // Insider Stock
    // /insider/0001219112/stock/0000104169
    $('#insiderStockClasses').DataTable({
        columnDefs: [
            { targets: [2], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rtpi'
    });

    $('#insiderStockTransactions').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#insiderStockTransactions').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            overflowLink,
            overflowOptions,
            pctOptionsSign,
            humanOptions['USD'],
            priceProperOptions,
            humanOptionsSign['number'],
            numberOrCurrencyOptions,
            dateFiledOptions,
            overflowOptions
        ],
        searching: true,
        dom: 'l<"w-52"f>rtpiB'
    });

    // Stock Stats
    // /stock-stats/m3
    $('#stockStats').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#stockStats').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            overflowLink,
            humanOptionsSign['USD'],
            humanOptionsSign['USD'],
            humanOptionsSign['USD'],
            numberOptionsSign,
            numberOptionsSign,
            numberOptionsSign,
            decimalOptions,
            decimalOptions,
            dateFiledOptions,
            dateFiledOptions
        ],
        columnDefs: [
            { targets: [2, 4, 5, 7, 10, 11], orderSequence: ['desc', 'asc'] }
        ],
        searching: true,
        dom: 'l<"w-52"f>rtpiB'
    });

    // Stock Stats
    // /stock-stats/m3
    $('#securityStats').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#securityStats').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            overflowLink,
            overflowOptions,
            miniPctOptions,
            miniPctOptions,
            miniPctOptions,
            numberOptionsSign,
            numberOptionsSign,
            numberOptionsSign,
            decimalOptions,
            humanOptionsSign['USD']
        ],
        columnDefs: [
            { targets: [3, 5, 6, 8, 10], orderSequence: ['desc', 'asc'] }
        ],
        searching: true,
        dom: 'l<"w-52"f>rtpiB'
    });

    // Grand Portfolio
    // /grand-portfolio
    $('#grandPortfolio').DataTable({
        ajax: {
            cache: true,
            error: errorHandler,
            url: $('#grandPortfolio').data('url')
        },
        buttons: buttonsDefault,
        columns: [
            overflowLink,
            overflowLink,
            overflowOptions,
            pctOptions,
            miniPctOptions,
            numberOptions,
            decimalOptions,
            humanOptions['USD'],
            humanOptionsSign['USD']
        ],
        columnDefs: [
            { targets: [3, 4, 5, 7, 8], orderSequence: ['desc', 'asc'] }
        ],
        searching: true,
        dom: 'l<"w-52"f>rtpiB'
    });

    // Insider reports
    // /insider/0001295084-shriram-kavitark-ram/reports
    $('#insiderStocks').DataTable({
        columnDefs: [
            { targets: [3], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rti'
    });

    $('#insiderFilings').DataTable({
        columnDefs: [
            { targets: [2, 3], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rtpi'
    });

    // Insider Report
    // /report/000162828023027380-shahar-shai-2023-08-01
    $('#insiderTransactions').DataTable({
        columnDefs: [
            { targets: [5, 6, 7, 8], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rti'
    });

    $('#insiderDerivatives').DataTable({
        columnDefs: [
            { targets: [5, 6, 7, 8], orderSequence: ['desc', 'asc'] }
        ],
        searching: false,
        dom: 'l<"w-52"f>rti'
    });

    // Submissions
    // /submissions/googl/SC13G
    $('#submissions').DataTable({
        columnDefs: [
            { targets: [3, 4], orderSequence: ['desc', 'asc'] }
        ],
        searching: true,
        dom: 'l<"w-52"f>rtpi'
    });

});
